@import "../../_globalColor";

.contact-div-main {
  display: flex;
}

.dark-mode a {
  color: $textColorDark;
}

.contact-div-main > * {
  flex: 1;
}

.contact-title {
  font-size: 56px;
  font-weight: 500;
  color: $textColor;
}

.contact-detail,
.contact-detail-email {
  font-size: 30px;
  font-weight: 500;
  color: $textColor;
  text-decoration: none;
}

.contact-detail:hover,
.contact-detail-email:hover {
  color: $textColor;
  text-shadow: 2px 1px 2px $contactDetailHoverTS;
  transition: all 0.3s;
}

.contact-div-main .contact-subtitle {
  //text-transform: uppercase;
  font-size: 23px;
  color: $subTitle;
  padding-bottom: 20px;
}

.contact-text-div {
  margin-top: 1.5rem;
}

.contact-margin-top {
  margin-top: 4rem;
}

.contact-image-div {
  max-width: 30%;
  //margin-right: 0rem;
  //right: 50px;
  margin-top: -5rem;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
  .contact-header .contact-title {
    font-size: 30px !important;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 20px !important;
    line-height: normal;
    text-align: center;
  }

  .contact-text-div {
    text-align: center;
    margin-top: 1.5rem;
  }

  .contact-detail,
  .contact-detail-email {
    font-size: 20px;
  }

  .contact-image-div {
    display: none;
  }
}
