@import "../../_globalColor";

.main .skills-heading {
  font-size: 56px;
  font-weight: 500;
  color: $textColor;
}

.main .project-subtitle {
  font-size: 23px;
  color: $subTitle;
}

.card-image {
  max-width: 100%;
  height: auto;
  width: 250px;
}
.project-detail .card-title {
  color: $buttonColor;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
}
.project-detail .card-subtitle {
  color: $buttonColor;
  font-size: 17px;
  margin-top: 1rem;
  line-height: 1.5rem;
}
.project-detail .project-card-footer {
  padding-top: 20px;
}
.project-card {
  display: flex;
  flex-direction: column;
  background-color: $lightBackground2;
  padding: 1.5rem;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
  justify-content: center;
}
.project-card-light {
  box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.project-card-dark {
  box-shadow: 0px 10px 30px -15px $darkBoxShadow;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.25s ease;
}

.project-detail {
  text-align: center;
}
.project-card-light:hover {
  box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}
.project-card-dark:hover {
  box-shadow: 0px 20px 30px -10px $darkBoxShadow;
}
.project-image {
  position: relative;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.project-card-footer span.main-button {
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  display: inline-flex;
  width: auto;
  font-size: auto;
}

.project-card-footer span.project-tag {
  background: $buttonColor;
  color: $lightBackground3;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 1rem;
  height: 2em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 1em;
  cursor: pointer;
  transition: 0.2s ease-in;
}

@media (max-width: 768px) {
  .main .skills-heading {
    font-size: 30px !important;
    margin-bottom: 12px;
    text-transform: uppercase;
  }
  .project-subtitle {
    font-size: 20px !important;
    text-align: center;
    line-height: 30px;
  }

  // .projects-container{
  //   display: grid;
  //   //grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  //   //grid-template-columns: 1.5fr 1.5fr;
  //   gap: 1rem ;
  //   //max-width: 85vw;
  //   overflow-x: scroll;
  //   scroll-behavior: smooth;
  //   transition: scroll 0.3s ease-in-out;
  //   grid-auto-flow: column;
  //   grid-auto-columns: 100%;

  // }
}
