@import "../../_globalColor";

.skills-main-div {
  display: flex;
  width: 100%;
  overflow: hidden;
}

// .skills-text-div {
//   margin-left: 50px;
// }

.skills-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}

.skills-image-div > img {
  max-width: 100%;
  height: auto;
}

.skills-heading {
  font-size: 56px;
  font-weight: 500;
  color: $textColor;
}
.skills-text-div .subTitle {
  color: $subTitle;
  font-size: 23px;
}

.skills-text-div .skills-text {
  color: $subTitle;
  font-size: 23px;
}

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
  .skills-text-div .skills-text {
    color: $subTitle;
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .skills-heading {
    font-size: 30px !important;
    text-align: center;
    text-transform: uppercase;
  }

  // .skills-main-div {
  //   flex-direction: column;
  // }
  // .skills-text-div {
  //   margin-left: 0px;
  //   margin: 20px;
  // }
  .skills-text-div .skills-text {
    font-size: 18px;
  }
  .skills-text-div .skills-text-subtitle {
    font-size: 20px !important;
    line-height: 30px;
    text-align: center;
    margin-top: 10px !important;
  }
  .skills-image-div {
    /* display: none; */
    order: 2;
  }
}
