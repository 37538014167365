@import "../../_globalColor";
@import "react-slideshow-image/dist/styles.css";

.experience-cards-div {
  display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  //display: flex;
  //max-width: 500px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  transition: scroll 0.3s ease-in-out;

  //align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: 33%; /* play with this to change height of the children, 50% will fill half */
  grid-template-columns: unset; /* do not set template columns and rows */
  grid-template-rows: unset;
}

.buttons {
  display: flex;
  justify-content: center;
}

.nav-btn {
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: #c3c3c3;
  border-radius: 6px;
  height: 50px;
  width: 50px;
  margin: 5px;
}

.experience-heading {
  color: $textColor;
  font-size: 56px;
  font-weight: 500;
  padding: 0 0 30px;
}

.experience-container {
  display: flex;
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.experience-container > * {
  flex: 1;
}

/* Media Query */

// @media (max-width: 1380px) {
//   .experience-heading {
//     font-size: 40px;
//   }
// }

@media (max-width: 768px) {
  .experience-heading {
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: -0.5rem;
  }

  .experience-cards-div {
    display: grid;
    max-width: 85vw;
    grid-auto-columns: 100%; /* play with this to change height of the children, 50% will fill half */
  }
}
