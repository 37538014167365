@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");
@import "../../_globalColor";

.containr {
  width: 70%;
  background: #fff;
  margin: auto;
  padding: 10px 50px;
  border-radius: 20px;
}

.skills-container {
  display: flex;
}

.skills-wrapper {
  width: 60%;
}

.skills-image {
  margin-right: 5px;
}

.title-text {
  text-align: center;
  color: $buttonColor;
  font-size: 56px;
  font-weight: 500;
  border-radius: 50px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.containr .skills-container .title {
  color: $buttonColor;
}

.containr .skill-box {
  width: 100%;
  margin: 25px 0;
}

.skill-box .title {
  display: block;
  font-size: 23px;
  font-weight: 600;
  color: #333;
}

.skill-box .skill-bar {
  height: 20px;
  width: 100%;
  border-radius: 6px;
  margin-top: 6px;
  background: rgba(0, 0, 0, 0.1);
}

.containr .skill-box .skill-per {
  position: relative;
  display: block;
  border-radius: 6px;
  background: rgb(35, 3, 95);
  opacity: 0;
}

.containr:hover .skill-box .skill-per {
  height: 100%;
  animation: progress 2s ease-in-out forwards;
}

.skill-per.html {
  width: 80%;
}

.skill-per.css {
  width: 75%;
}

.skill-per.javascript {
  width: 75%;
}

.skill-per.reactjs {
  width: 75%;
}

.skill-per.expressjs {
  width: 75%;
}

.skill-per.database {
  width: 75%;
}

@keyframes progress {
  0% {
    width: 0;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .containr {
    width: 90%;
  }
  .title-text {
    font-size: 30px;
  }
  .skills-container {
    display: block;
  }
  .skills-wrapper {
    width: 100%;
  }
  // .skills-image {
  //     visibility: hidden;
  // }
}

// .skill-per .tooltip{
//     position: absolute;
//     right: -14px;
//     top: -28px;
//     font-size: 20px;
//     color: black;
// }

// .tooltip::before {
//     content: '';
//     position: absolute;
//     left: 50%;
//     bottom: -2px;
//     height: 10px;
//     width: 10px;
//     z-index: -1;
//     background-color: white;
//     transform: translateX(-50%) rotate(45deg);
// }
