@import "../../_globalColor";

.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}

.greeting-main {
  display: flex;
}

.greet-main .subTitle {
  color: $textColor !important;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}

.button-greeting-div {
  display: flex;
}

.modal-dialog {
  top: -20px;
}

.modal-content {
  height: 95vh;
  width: 100vw;
}
.modal-body > iframe {
  height: 100%;
  width: 100%;
}

.pdf-title-resume {
  color: $buttonColor;
  font-weight: bold;
  font-size: 35px;
  //padding-left: 45%;
}

.pdf-title-coverLetter {
  color: $buttonColor;
  font-weight: bold;
  font-size: 35px;
  //padding-left: 45%;
}

.greeting-text {
  font-size: 44px;
  line-height: 1.1;
  color: $textColor !important;
}

.greet-main .greeting-text-p {
  font-size: 23px;
  line-height: 35px;
  text-align: justify;
  max-width: 100%;
}

.greeting-image-div > img {
  max-width: 100%;
  //height: auto;
  width: 500px;
  height: 700px;
  margin-left: 150px;
}

.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Media Query */
@media (max-width: 1380px) {
  .modal-dialog {
    top: 0px;
  }
  .greeting-text {
    font-size: 38px;
  }
  .greeting-text-p {
    font-size: 20px !important;
    line-height: 28px !important;
    padding-top: 1rem;
  }

  .greeting-image-div > img {
    max-width: 100%;
    height: auto;
    width: auto;
    margin-left: 100px;
  }

  // .greeting-image-div {
  //   height: auto;
  //   width: auto;
  //   margin-right: auto;
  // }

  // .modal-body {
  //   position: relative !important;
  //   display: block !important;
  //   width: 100% !important;
  //   padding-bottom:56.25% !important;
  // }
  // .modal-body > iframe {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
}

@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 20px !important;
    text-align: center !important;
    line-height: 30px !important;
  }
  .greeting-main {
    display: block;
  }
  .greeting-image-div > img {
    height: auto;
    width: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    height: 650px !important;
    width: 100% !important;
  }

  .pdf-title-resume {
    font-size: 22px;
    //padding-left: 32%;
  }
  .pdf-title-coverLetter {
    font-size: 22px;
    //padding-left: 22%;
  }
}

// @media only screen and (min-width: 740px) and (orientation: portrait) {
//   .button-greeting-div {
//     justify-content: space-around;
//   }
//   .greeting-text {
//     font-size: 30px;
//     text-align: center;
//   }
//   .greeting-text-p {
//     font-size: 16px;
//     line-height: normal;
//     text-align: center;
//   }
//   .greeting-main {
//     display: block;
//   }
//   .greeting-image-div > img{
//     height: auto;
//     width: auto;
//     margin-right: auto;
//     margin-left: auto;
//   }
// }

// @media (max-width: 320px) {
//   .main {
//     width: auto;
//   }
// }
